<template>
  <Sheet
    :value="value"
    width="45vw"
    height="80vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title>
      {{ channel.id ? "Edit Channel" : "Add Channel" }}
    </template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name="Name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="name"
                label="Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="mode === 'ADD'"
              v-slot="{ errors }"
              name="Type"
              :rules="{ required: true }"
            >
              <SingleChoiceField
                v-model="type"
                is-mandatory
                label="Type"
                :options="types"
                :error="errors[0]"
                class="q-mb-md singleChoice"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="type === 'EXISTING'"
              v-slot="{ errors }"
              name="Teams"
              :rules="{ required: type === 'EXISTING' }"
            >
              <SelectField
                v-model="teamId"
                label="Teams"
                is-mandatory
                :options="teamList"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="type === 'EXISTING' && mode === 'ADD'"
              v-slot="{ errors }"
              name="Channel Type"
              :rules="{ required: true }"
            >
              <SingleChoiceField
                v-model="channelType"
                is-mandatory
                label="Type"
                :options="types"
                :error="errors[0]"
                class="q-mb-md singleChoice"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="type === 'EXISTING' && channelType === 'EXISTING'"
              v-slot="{ errors }"
              name="Channels"
              :rules="{ required: channelType === 'EXISTING' }"
            >
              <SelectField
                v-model="channelId"
                label="Channels"
                is-mandatory
                :options="channelList"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="type === 'ADD'"
              v-slot="{ errors }"
              name="Team Name"
              :rules="{ required: type === 'ADD' }"
            >
              <TextField
                v-model="teamName"
                label="Team Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="channelType === 'ADD'"
              v-slot="{ errors }"
              name="Channel Name"
              :rules="{ required: channelType === 'ADD' }"
            >
              <TextField
                v-model="channelName"
                label="Channel Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="type === 'ADD' && mode === 'ADD'"
              v-slot="{ errors }"
              name="User List"
              :rules="{ required: type === 'ADD' }"
            >
              <MultiSelectField
                v-model="members"
                label="User List"
                is-mandatory
                :options="userList"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <TextField
              v-if="mode === 'ADD'"
              v-model="teamOwnerMail"
              label="Owner Email"
              class="q-mb-md"
            />
          </div>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        :label="channel.id ? 'Update' : 'Add'"
        color="primary"
        @click="saveTeams"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { connector, user } from "@/api/factory.js";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "AddChannelInTeams",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    SelectField,
    SingleChoiceField,
    MultiSelectField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    channel: {
      type: Object,
      default: () => {},
    },

    connectorId: {
      type: [String, Number],
      required: true,
    },

    mode: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      name: "",
      type: "ADD",
      types: [
        {
          id: this.$nano.id(),
          label: "Existing",
          value: "EXISTING",
        },
        {
          id: this.$nano.id(),
          label: "Add",
          value: "ADD",
        },
      ],
      channelType: "ADD",
      teamId: "",
      channelId: "",
      teamName: "",
      channelName: "",
      teamList: [],
      members: [],
      userList: [],
      channelList: [],
      teamOwnerMail: "",
    };
  },

  watch: {
    type: {
      immediate: true,
      handler() {
        if (this.type === "ADD") {
          this.channelType = "ADD";
        }
      },
    },

    channel: {
      immediate: true,
      handler() {
        this.reset();
        if (this.channel.id) {
          this.channelType = "EXISTING";
          this.type = "EXISTING";

          this.name = this.channel.name;
          this.teamId = this.channel.inData
            ? JSON.parse(this.channel.inData).teamId
            : "";
          this.teamName = this.channel.inData
            ? JSON.parse(this.channel.inData).teamName
            : "";
          this.channelId = this.channel.inData
            ? JSON.parse(this.channel.inData).channelId
            : "";
          this.channelName = this.channel.inData
            ? JSON.parse(this.channel.inData).channelName
            : "";
          // this.channelName = channelName.value;
        }
      },
    },

    teamId() {
      if (this.teamId) {
        this.getChannelList(this.teamId);
      }
    },
  },

  created() {
    // this.getUsers();
    this.getTeamsList();
    this.getTeamsUserList();
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.name = "";
      this.channelName = "";
      this.teamName = "";
      this.teamId = "";
      this.channelId = "";
      this.members = [];
      this.teamOwnerMail = "";
    },

    saveTeams() {
      if (this.mode === "ADD") {
        this.addChannel();
      } else {
        this.editChannel();
      }
    },

    async addChannel() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.$store.commit("showLoadingBar");

      let input = {
        name: this.name,
        connectorId: this.connectorId,
        teamOwnerMail: this.teamOwnerMail,
        membersMailList: this.members,
      };
      if (this.type === "EXISTING") {
        this.$set(input, "teamId", this.teamId);
        let name = this.teamList.find((item) => item.value === this.teamId);
        this.$set(input, "teamName", name?.label);
      } else {
        this.$set(input, "teamName", this.teamName);
        this.$set(input, "teamId", "");
      }
      if (this.channelType === "EXISTING") {
        this.$set(input, "channelId", this.channelId);
        let name = this.channelList.find(
          (item) => item.value === this.channelId
        );
        this.$set(input, "channelName", name?.label);
      } else {
        this.$set(input, "channelName", this.channelName);
        this.$set(input, "channelId", "");
      }

      const { error, payload } = await connector.addTeamsChannel(input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);

      this.$alert.success(`Channel saved successfully`);

      this.closeSheet();
      this.$emit("save");
    },

    async editChannel() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.$store.commit("showLoadingBar");
      let teamName = this.teamList.find((item) => item.value === this.teamId);
      let channelName = this.channelList.find(
        (item) => item.value === this.channelId
      );

      let inDataPoint = [
        {
          teamId: this.teamId,
          teamName: teamName?.label,
        },
        {
          channelId: this.channelId,
          channelName: channelName?.label,
        },
      ];

      let input = {
        name: this.name,
        hubPoint: this.teamId,
        inDataPoint: JSON.stringify(inDataPoint),
      };

      const { error, payload } = await connector.updateLookUp(
        this.channel.id,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);

      this.$alert.success(`channel edited successfully`);

      this.closeSheet();
      this.$emit("save");
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value || user.loginName,
          value: user.value || user.loginName,
        }));
    },

    async getTeamsList() {
      this.channelList = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getChannelList({
        connectorId: this.connectorId,
        listType: "teamsList",
        teamId: "",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);

      if (payload.TeamLists.length) {
        this.teamList = [];
        payload.TeamLists.forEach((item) => {
          this.teamList.push({
            id: this.$nano.id(),
            label: item.TeamName,
            value: item.TeamID,
          });
        });
      }
    },

    async getChannelList(teamId) {
      this.channelList = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getChannelList({
        connectorId: this.connectorId,
        listType: "channelList",
        teamId: teamId,
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.Channels.length) {
        this.channelList = [];
        payload.Channels.forEach((item) => {
          this.channelList.push({
            id: this.$nano.id(),
            label: item.ChannelName,
            value: item.ChannelID,
          });
        });
      }
    },

    async getTeamsUserList() {
      this.channelList = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getChannelList({
        connectorId: this.connectorId,
        listType: "usersList",
        teamId: "",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);

      if (payload.UserLists.length) {
        this.userList = [];
        payload.UserLists.forEach((item) => {
          this.userList.push({
            id: this.$nano.id(),
            label: item.Email,
            value: item.Email,
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
#query-result {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;
    // margin-right: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}
</style>
